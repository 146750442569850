'use strict';

import {formTool} from './form_tool';

// nest した Object から値を取り出す
const getProperty = (object, propertyPath) => {
  if (!object) {
    return undefined;
  }

  let result = object;
  const propertyArray = propertyPath.split('.');
  for (let i = 0; i <= propertyArray.length - 1; i += 1) {
    if (propertyArray[i] === '') {
      return undefined;
    }
    if (typeof result[propertyArray[i]] === 'undefined') {
      return undefined;
    }
    result = result[propertyArray[i]];
  }
  return result;
};

const repeaterMaster = {
  'employee[employee_workplaces_attributes]': {
    path: 'employee.employee_workplaces_attributes',
  },
  'employee[employee_assignments_attributes]': {
    path: 'employee.employee_assignments_attributes',
  },
  'employee[employee_phones_attributes]': {
    path: 'employee.employee_phones_attributes',
  },
  'employee[employee_commute_allowance_scheme_attributes][employee_commute_paths_attributes]': {
    path: 'employee.employee_commute_allowance_scheme_attributes.employee_commute_paths_attributes',
  },
  'employee[employee_commute_informations_attributes]': {
    path: 'employee.employee_commute_informations_attributes',
  },
  'employee[bank_accounts_attributes]': {
    path: 'employee.bank_accounts_attributes',
  },
  'employee[maternity_and_childcares_attributes]': {
    path: 'employee.maternity_and_childcares_attributes',
  },
  'employee[families_attributes]': {
    path: 'employee.families_attributes',
    innerModelName: 'family_address_attributes',
  },
};

// form の type に応じて、Local Storage の値を反映していく
const renderFormValue = (targetForm, formValue) => {
  if (!targetForm) {
    return;
  }

  const formType = formTool.getType(targetForm);
  switch (formType) {
    case 'text':
    case 'textarea':
    case 'number':
      $(targetForm).val(formValue);
      break;
    case 'select':
      if ($(targetForm).find('option[value="' + formValue + '"]').length !== 0) {
        $(targetForm).selectpicker('val', formValue);
      }
      break;
    case 'radio':
      $(targetForm).prop('checked', true);
      break;
    case 'checkbox':
      if (typeof formValue === 'object') {
        formValue = formValue[0];
      }
      $(targetForm).prop('checked', formValue);
      break;
  }
};

// 同じ URL のフォーム情報であるか検証
const validateURL = (currentUrl) => {
  if (currentUrl && localStorage.getItem('URL') !== currentUrl) {
    localStorage.clear();
    localStorage.setItem('URL', currentUrl);
  }
};

// 保存期間が過ぎた場合に Local Storage を削除する。
const clearExpiredStorage = (currentTime) => {
  const localStorageUpdatedAt = Number(localStorage.getItem('updated_at'));
  // 最終更新日時より3日後に削除する
  const timeLimit = 1000 * 60 * 60 * 24 * 3;
  const isUpdateAtOld = currentTime - localStorageUpdatedAt > timeLimit;

  if (!localStorageUpdatedAt || isUpdateAtOld) {
    localStorage.clear();
  }
};

const insertStorage = (targetForm) => {
  const formName = $(targetForm).attr('name');
  const formType = formTool.getType(targetForm);

  if ($(targetForm).parents('.repeater').length) {
    // repeater の場合 Local Storage には個々のフォームではなく、repeater が持つ値全体を入れる。
    // key と value は以下の形式で保存しておく。
    // key: 'repeater|data-repeater-list の値'
    // value: repeaterVal で取得した値
    const repeaterForm = $(targetForm).parents('.repeater');
    const repeaterObject = repeaterForm.repeaterVal();
    const repeaterName = repeaterForm.find('[data-repeater-list^="employee"]').attr('data-repeater-list');
    localStorage.setItem(`repeater|${repeaterName}`, JSON.stringify(repeaterObject));
  } else {
    let formValue;
    if (formType === 'checkbox') {
      formValue = $(targetForm).prop('checked');
    } else {
      formValue = $(targetForm).val();
    }

    // ファイルは自動保存の対象外
    if (formType !== 'file') {
      const formData = {form_value: formValue, form_type: formType};
      localStorage.setItem(formName, JSON.stringify(formData));
    }
  }
};

const saveForm = (currentTime) => {
  $('input, textarea, select').each((i, e) => {
    $(e).on('change', (event) => {
      const targetForm = event.currentTarget;
      insertStorage(targetForm);

      localStorage.setItem('company_id', gon.company_id);
      localStorage.setItem('updated_at', currentTime);
    });
  });
};

/*
 * ラジオボタンでチェックをするフォームElementをidで取得する。
 * localStorageKeyはname属性の値であるため、以下処理でid属性に変更を行う
 *
 * name -> idへの変更処理：
 *   localStorageKeyの中括弧を_に置換したものに
 *   employee_custom_field_definition_select_itemsのid(UUID)
 *
 * LocalStorageKeyの例:
 *   employee[employee_custom_field_values_attributes][14][employee_custom_field_definition_select_item_id]
 * 取得したいラジオボタンのIDの例:
 *   employee_employee_custom_field_values_attributes_14_employee_custom_field_definition_select_item_id_\
 *                                                                                 c5f4e5ec-2d7a-4d0a-818c-8ed6a4c73d49
 */
const getRadioForm = (localStorageKey, localStorageValue) => {
  // チェックをつけるフォームを取得
  const radioKey = localStorageKey
      .replace(/\]\[/g, '_') // 中括弧の ][ を _ に置換
      .replace(/\]/g, '_') // 末尾の ] を _ に置換
      .replace(/\[/g, '_') + localStorageValue.form_value; // [ を _ に置換し、UUIDを繋げる
  return $(`[id='${radioKey}'`).filter('[type!="hidden"]')[0];
};

const renderStorage = () => {
  for (let i = 0; i < localStorage.length; i++) {
    // Local Storage から一行ずつデータを取得
    const localStorageKey = localStorage.key(i);
    if (!localStorageKey.match(/employee/)) {
      continue;
    }
    const localStorageValue = JSON.parse(localStorage.getItem(localStorageKey));

    // Local Storage を反映させるフォームを取得
    let targetForm = $(`[name='${localStorageKey}'`).filter('[type!="hidden"]')[0];

    // 通常のフォームと repeater を使ったフォームで場合分け
    if (targetForm) {
      // 通常のフォーム
      // radio の場合、チェックをするフォームを取得
      if (formTool.getType(targetForm) === 'radio') {
        targetForm = getRadioForm(localStorageKey, localStorageValue);
      }
      renderFormValue(targetForm, localStorageValue.form_value);
    } else if (localStorageKey.match(/^repeater/)) {
      // 「家族」の入力欄には家族の追加ボタンに加え、給与以外の所得の追加ボタンが存在する。
      // 「家族」の場合、repeaterAddButtonとして期待する値は、家族の追加ボタンに対応する要素である。
      // 従って、給与以外の所得の追加ボタンに inner-add-button クラスを付与しておき、
      // (@see: app/views/employees/forms/_family_other_incomes_form.html.slim)
      // ボタン要素の取得処理では inner-add-button クラスが付与された要素を除外している。
      const repeaterName = localStorageKey.slice('repeater|'.length);
      const repeaterForm = $(`[data-repeater-list='${repeaterName}']`);
      const repeaterAddButton = repeaterForm.parents('.repeater').find('[data-repeater-create=""]').not('.inner-add-button'); // eslint-disable-line
      const innerModelName = repeaterMaster[repeaterName]['innerModelName'];
      const repeaterList = getProperty(localStorageValue, repeaterMaster[repeaterName].path);

      repeaterList.forEach((row, i) => {
        if (!row.id && i !== 0 && repeaterAddButton[0]) {
          // 追加ボタンを click する操作を再現し、フォームを追加
          // jquery.repeater のドキュメントを確認したが、他に方法がなかった。
          repeaterAddButton[0].click();
        }
        for (const [columnName, formValue] of Object.entries(row)) {
          let formName;
          if (columnName !== innerModelName) {
            formName = `${repeaterName}[${i}][${columnName}]`;
            targetForm = $(`[name='${formName}'`).filter('[type!="hidden"]')[0];
            renderFormValue(targetForm, formValue);
          } else {
            // repeater の中に子の Model がある場合
            for (const [innerColumnName, innerFormValue] of Object.entries(formValue)) {
              formName = `${repeaterName}[${i}][${columnName}][${innerColumnName}]`;
              targetForm = $(`[name='${formName}'`).filter('[type!="hidden"]')[0];
              renderFormValue(targetForm, innerFormValue);
            }
          }
        }
      });
    }
  }
};

export const formStorage = {
  init() {
    if (gon.is_form_storage_enabled) {
      const currentTime = (new Date()).getTime();
      clearExpiredStorage(currentTime);
      validateURL(gon.current_url);
      saveForm(currentTime);
    }
  },
  render() {
    if (gon.is_form_storage_enabled) {
      renderStorage();
    }
  },
  clearExpiredStorage() {
    const currentTime = (new Date()).getTime();
    clearExpiredStorage(currentTime);
  },
  insertStorage(targetForm) {
    if (gon.is_form_storage_enabled) {
      insertStorage(targetForm);
    }
  },
};
